import React, { useState, useEffect } from 'react';

const loadAdPasswordPolicy = (minPwdLength) => {
  let policy = {
    minPwdLength: minPwdLength,
    pwdHistoryLength: 1,
  };
  const $adPasswordPolicy = document.querySelector('ul.active-directory');

  if ($adPasswordPolicy) {
    if (!policy.minPwdLength) {
      const $lengthValidation = $adPasswordPolicy.querySelector('.lg');
      if ($lengthValidation) {
        policy.minPwdLength = Number(
          $lengthValidation.dataset.minPasswordLength
        );
      }
    }

    const $passwordHistoryValidation = $adPasswordPolicy.querySelector(
      '.history'
    );
    if ($passwordHistoryValidation) {
      policy.pwdHistoryLength = Number(
        $passwordHistoryValidation.dataset.passwordHistoryLength
      );
    }
  }

  if (!policy.minPwdLength) {
    policy.minPwdLength = 6;
  }

  return policy;
};

const PasswordValidationInput = (props) => {
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [style, setStyle] = useState('');
  const [confirmStyle, setConfirmStyle] = useState('');
  const [togglePassword, setTogglePassword] = useState(false);
  const [toggleConfirm, setToggleConfirm] = useState(false);
  const username = props.username.split('@')[0];
  const tenantMinPasswordLength = props.passwordMinLength || 8;

  // Determined based on data already loaded into DOM.
  const adPasswordPolicy = loadAdPasswordPolicy(props.adPasswordMinLength);

  useEffect(() => {
    handleValidation('');
  }, []);

  const strongRegex = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
  );
  const mediumRegex = new RegExp(
    '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})'
  );
  const characters = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])'
  );

  const escapeRegex = (string) => {
    return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  };

  const upperCaseLetters = new RegExp('^(?=.*[A-Z])');
  const lowerCaseLetters = new RegExp('^(?=.*[a-z])');
  const sequence = new RegExp('^(?!.*(.)\\1+)');
  const numbers = new RegExp('^(?=.*[0-9])');
  const special = new RegExp('^(?=.*[!@#$%^&*])');
  const length = new RegExp(`^(?=.{${tenantMinPasswordLength},})`);
  const lengthAd = new RegExp(`^(?=.{${adPasswordPolicy.minPwdLength},})`);
  const length40 = new RegExp('^(?!.{40,})');
  const partsOfThreeLetters = username
    .match(/.{3}/g)
    .concat(
      username.substr(1).match(/.{3}/g),
      username.substr(2).match(/.{3}/g)
    )
    .filter((n) => n);

  const usernameParts = partsOfThreeLetters.map((seg) => {
    return escapeRegex(seg);
  });
  const usernameValidation = new RegExp(
    `^(?!.*(${usernameParts.join('|')}).*$)`
  );

  const handleValidation = (strValue) => {
    if (strongRegex.test(strValue)) {
      setStyle('green');
    } else if (mediumRegex.test(strValue)) {
      setStyle('orange');
    } else {
      setStyle('red');
    }

    validateAdRules(strValue);
    validateStandardRules(strValue);
  };

  const validateAdRules = (strValue) => {
    const $adList = document.querySelector('.ad-list');
    if ($adList) {
      // Remove the list class to avoid double bullet points.
      $adList.classList.remove('list');
      validateStr(numbers, '.digits', strValue);
      validateStr(usernameValidation, '.user', strValue);
      validateStr(special, '.sc', strValue);
      validateStr(lowerCaseLetters, '.lc', strValue);
      validateStr(upperCaseLetters, '.uc', strValue);
      validateStr(lengthAd, '.lg', strValue);
    }
  };

  const validateStandardRules = (strValue) => {
    if (document.querySelector('.password-rules')) {
      if (document.querySelector('#r0 i')) {
        document
          .querySelector('#r0 i')
          .classList.add('fa-li', 'fa', 'fa-minus');
        validateStr(numbers, '#r7', strValue);
        validateStr(special, '#r8', strValue);
        validateStr(lowerCaseLetters, '#r9', strValue);
        validateStr(upperCaseLetters, '#r6', strValue);
        validateStr(length, '#r1', strValue);
        validateStr(sequence, '#r4', strValue);
        validateStr(sequence, '#r5', strValue);
        validateStr(length40, '#r3', strValue);
        validateStr(characters, '#r2', strValue);
      }
    }
  };

  const validateStr = (regex, selector, strValue) => {
    if (!regex.test(strValue)) {
      document.querySelector(selector).style.color = 'red';
      document
        .querySelector(`${selector} i`)
        .classList.add('fa-li', 'fa', 'fa-times');
    } else {
      document.querySelector(selector).style.color = 'green';
      document.querySelector(`${selector} i`).classList.remove('fa-times');
      document
        .querySelector(`${selector} i`)
        .classList.add('fa-li', 'fa', 'fa-check');
    }
  };

  const handleConfirm = (password, confirm) => {
    if (password !== confirm) {
      setConfirmStyle('red');
    } else {
      setConfirmStyle('green');
    }
  };

  const spanStyle = {
    float: 'right',
    marginLeft: '450px',
    marginTop: '-25px',
    marginRight: '5px',
    fontSize: '20px',
    position: 'relative',
  };

  return (
    <>
      <div className='row form-group'>
        <div style={{ width: '100%' }}>
          <input
            id='forms_change_password_new_password'
            name='forms_change_password[new_password]'
            placeholder='New Password'
            className='form-control'
            style={{ color: style }}
            type={togglePassword === false ? 'password' : 'text'}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              handleValidation(e.target.value);
              handleConfirm(e.target.value, confirm);
            }}
          />
          <span
            onClick={() => setTogglePassword(!togglePassword)}
            className={`show-password-icon eye_css_class fa fa-fw fa-eye${
              togglePassword == true ? '-slash' : ''
            } field-icon`}
            style={spanStyle}
          ></span>
        </div>
      </div>
      <div className='row form-group'>
        <div style={{ width: '100%' }}>
          <input
            id='forms_change_password_new_password_confirmation'
            name='forms_change_password[new_password_confirmation]'
            placeholder='Confirm New Password'
            className='form-control'
            style={{ color: confirmStyle }}
            type={toggleConfirm === false ? 'password' : 'text'}
            value={confirm}
            onChange={(e) => {
              setConfirm(e.target.value);
              handleConfirm(password, e.target.value);
            }}
          />
          <span
            onClick={() => setToggleConfirm(!toggleConfirm)}
            className={`show-password-icon eye_css_class fa fa-fw fa-eye${
              toggleConfirm == true ? '-slash' : ''
            } field-icon`}
            style={spanStyle}
          ></span>
        </div>
      </div>
    </>
  );
};

export default PasswordValidationInput;
