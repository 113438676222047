import React, { useEffect, useState } from "react";

const PasswordGenerator = ({ persisted }) => {
  const [clicked, setClicked] = useState(false);
  const [passwordLength, setPasswordLength] = useState(16);
  const [specialCharacters, setSpecialCharacters] = useState(true);
  const [lowerCaseCharacters, setLowerCaseCharacters] = useState(true);
  const [upperCaseCharacters, setUpperCaseCharacters] = useState(true);
  const [numberCharacters, setNumberCharacters] = useState(true);
  const [passwordQuality, setPasswordQuality] = useState("Good");

  const handleCharacterOptions = (character, value) => {
    let selectedCount = getSelectedCount();

    if (selectedCount == 1 && value == false) {
      return;
    }

    switch (character) {
      case "lower":
        setLowerCaseCharacters(value);
        break;
      case "upper":
        setUpperCaseCharacters(value);
        break;
      case "number":
        setNumberCharacters(value);
        break;
      case "special":
        setSpecialCharacters(value);
        break;
    }
  };

  const getSelectedCount = () => {
    let selectedCount = 0;

    lowerCaseCharacters ? (selectedCount += 1) : null;
    upperCaseCharacters ? (selectedCount += 1) : null;
    numberCharacters ? (selectedCount += 1) : null;
    specialCharacters ? (selectedCount += 1) : null;

    return selectedCount;
  };

  const generatePassword = (passwordLength) => {
    const lowerCase = "abcdefghijklmnopqrstuvwxyz";
    const upperCase = lowerCase.toUpperCase();
    const numberChars = "0123456789";
    const specialChars = '!"@$%+-_?^&*()';

    let generatedPassword = "";
    let restPassword = "";
    let restArray = [];
    let selectedCount = getSelectedCount();

    if (lowerCaseCharacters) {
      restArray = [...restArray, ...lowerCase];
    }

    if (upperCaseCharacters) {
      restArray = [...restArray, ...upperCase];
    }

    if (numberCharacters) {
      restArray = [...restArray, ...numberChars];
    }

    if (specialCharacters) {
      restArray = [...restArray, ...specialChars];
    }

    const restLength = passwordLength % 4;
    const usableLength = passwordLength - restLength;
    const generateLength = usableLength / selectedCount;

    const randomString = (char) => {
      return char[Math.floor(Math.random() * char.length)];
    };

    for (let i = 0; i <= generateLength - 1; i++) {
      generatedPassword += `${
        lowerCaseCharacters ? randomString(lowerCase) : ""
      }${upperCaseCharacters ? randomString(upperCase) : ""}${
        numberCharacters ? randomString(numberChars) : ""
      }${specialCharacters ? randomString(specialChars) : ""}`;
    }

    for (let i = 0; i <= restLength - 1; i++) {
      restPassword += randomString(restArray);
    }

    if (generateLength % 2 != 0) {
      restPassword += randomString(restArray);
    }

    checkPasswordQuality(selectedCount);

    return generatedPassword + restPassword;
  };

  const checkPasswordQuality = (selectedCharactersCount) => {
    if (selectedCharactersCount > 3 && passwordLength >= 16) {
      setPasswordQuality("Good");
    } else if (selectedCharactersCount >= 2 && passwordLength >= 8) {
      setPasswordQuality("Medium");
    } else {
      setPasswordQuality("Weak");
    }
  };

  const setQualityColor = () => {
    switch (passwordQuality) {
      case "Good":
        return "green";
      case "Medium":
        return "darkgoldenrod";
      case "Weak":
        return "red";
    }
  };

  const setPassword = () => {
    if (clicked) {
      document.getElementById("password_app_credential_password").value =
        generatePassword(passwordLength);
    }
  };

  const copyToClipboard = () => {
    const el = document.getElementById("password_app_credential_password");
    el.select();
    document.execCommand("copy");
  };

  useEffect(() => {
    setPassword();
  }, [
    clicked,
    specialCharacters,
    numberCharacters,
    upperCaseCharacters,
    lowerCaseCharacters,
    passwordLength,
  ]);

  if (!clicked) {
    return (
      <button
        title="Generate New Password"
        type="button"
        className="btn success btn-link"
        onClick={() => setClicked(!clicked)}>
        PASSWORD GENERATOR
      </button>
    );
  }

  return (
    <div style={{ ...styles.center, ...styles.generatorContainer }}>
      <div
        style={{
          ...styles.center,
          ...styles.buttonsContainer,
          ...styles.quality,
        }}>
        <span style={{ color: setQualityColor() }}>
          Password Quality : {passwordQuality}
        </span>
      </div>
      <div
        style={{
          ...styles.center,
          ...styles.buttonsContainer,
          ...styles.optionButtons,
        }}>
        <button
          title="Copy To Clipboard"
          onClick={() => copyToClipboard()}
          style={{
            ...styles.iconContainer,
            ...styles.optionButton,
          }}
          type="button"
          className="btn success">
          <span className="fa fa-fw fa-copy field-icon"></span>
        </button>
        <button
          title="Generate New Password"
          onClick={() => setPassword()}
          style={{ ...styles.iconContainer, ...styles.optionButton }}
          type="button"
          className="btn success">
          <span className="fa fa-fw fa-undo field-icon"></span>
        </button>
        <div style={{ ...styles.center, flexDirection: "column" }}>
          <label htmlFor="passwordLength">Password Length:</label>
          <input
            style={{ ...styles.numberInput }}
            onChange={(e) =>
              setPasswordLength(e.target.value > 30 ? 30 : e.target.value)
            }
            value={passwordLength}
            type="number"
            id="passwordLength"
            min="1"
            max="30"
            name="quantity"
          />
        </div>
      </div>
      <div style={{ ...styles.center, ...styles.buttonsContainer }}>
        <div
          className={`passwordGenerationHover ${
            lowerCaseCharacters ? "passwordGenerationSelected" : ""
          }`}
          style={{
            ...styles.iconContainer,
            ...styles.characterBtnContainer,
            ...styles.center,
          }}>
          <button
            onClick={() =>
              handleCharacterOptions("lower", !lowerCaseCharacters)
            }
            style={styles.characterBtn}
            type="button"
            className="btn success">
            a - z
          </button>
        </div>
        <div
          className={`passwordGenerationHover ${
            upperCaseCharacters ? "passwordGenerationSelected" : ""
          }`}
          style={{
            ...styles.iconContainer,
            ...styles.characterBtnContainer,
            ...styles.center,
          }}>
          <button
            onClick={() =>
              handleCharacterOptions("upper", !upperCaseCharacters)
            }
            style={styles.characterBtn}
            type="button"
            className="btn success">
            A - Z
          </button>
        </div>
        <div
          className={`passwordGenerationHover ${
            numberCharacters ? "passwordGenerationSelected" : ""
          }`}
          style={{
            ...styles.iconContainer,
            ...styles.characterBtnContainer,
            ...styles.center,
          }}>
          <button
            onClick={() => handleCharacterOptions("number", !numberCharacters)}
            style={styles.characterBtn}
            type="button"
            className="btn success">
            0 - 9
          </button>
        </div>
        <div
          className={`passwordGenerationHover ${
            specialCharacters ? "passwordGenerationSelected" : ""
          }`}
          style={{
            ...styles.iconContainer,
            ...styles.characterBtnContainer,
            ...styles.center,
          }}>
          <button
            onClick={() =>
              handleCharacterOptions("special", !specialCharacters)
            }
            style={styles.characterBtn}
            type="button"
            className="btn success">
            $%^!*
          </button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  generatorContainer: {
    height: "10vh",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  iconContainer: {
    color: "#969696",
    padding: "2%",
    border: "solid 1px #969696",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonsContainer: {
    width: "100%",
    height: "100%",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
    paddingTop: "3%",
    gap: "2%",
  },
  characterBtnContainer: {
    borderRadius: "5px",
    padding: "0",
    flex: "1 1 2%",
    border: "solid 1px #0451a1",
    minWidth: "80px",
  },
  characterBtn: {
    fontWeight: "bold",
    fontSize: "85%",
    width: "100%",
  },
  numberInput: {
    textAlign: "center",
    borderRadius: "5px",
    border: "solid #0450a1c2 2px",
    width: "100%",
  },
  quality: {
    fontWeight: "bold",
    justifyContent: "flex-start",
  },
  optionButtons: {
    justifyContent: "flex-start",
    alignItems: "flex-end",
  },
  optionButton: {
    maxHeight: "4vh",
    marginRight: "5%",
  },
};

export default PasswordGenerator;
